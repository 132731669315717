import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainWrap mt-0 pt-5"},[_c(VLayout,{staticClass:"ma-10"},[_c(VFlex,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[_c(VImg,{staticClass:"ma-10",attrs:{"max-width":"25%","src":require("../assets/logoFarbe.svg")},on:{"click":function($event){return _vm.toHome()}}})],1)],1),_c(VLayout,{attrs:{"row":"","wrap":"","align-center":""}},[_c(VFlex,[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5252.14697169847!2d9.359268876222963!3d48.8377369713293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799c863de4488d5%3A0xdc21bb8dac65bbcc!2sPorschering%208%2C%2071404%20Korb!5e0!3m2!1sde!2sde!4v1743961544965!5m2!1sde!2sde","width":"100%","height":"400","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}}),_c('div',{staticClass:"mt-10 mb-10",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(" Unser Standort ist "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Porschering 8, 71404 Korb im Rems-Murr-Kreis, Region Stuttgart.")])]),_c('br'),_c('br'),_c('span',[_vm._v(" Lieber Besucher, unseren Standort sehen Sie auf der eingeblendeten Karte. ")])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }