<template>
  <v-app>
    <v-footer bottom fixed color="black">
      <v-row justify="center">
        <v-btn
          class="mt-7"
          v-for="link in links"
          :key="link.name"
          color="white"
          text
          rounded
          link :to="link.route"
        >
          {{ link.name }}
        </v-btn>
        <v-col color="black" class="py-4 text-center white--text" cols="12">
          <a href="https://www.instagram.com/ingenieurbuero.bachmann/" target="_blank" class="customHyperlink">
          <v-icon color="#fff">mdi-instagram</v-icon>
           Instagram </a> <br>
          {{ new Date().getFullYear() }} - &copy; Ingenieurbüro Bachmann
        </v-col>
      </v-row>
    </v-footer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    links: [{name: "Home", route: "/"}, {name: "Über uns", route: "/ueberuns"}, {name: "Impressum", route: "/impressum"}, {name: "Datenschutz", route: "/datenschutz"}, {name: "Kontakt", route: "/kontakt"}, {name: "Anfahrt", route: "/anfahrt"}],
  })
};
</script>
<style>
.mainWrap {
  margin: 30px 0px 30px 0px;
  padding-bottom: 108px;
}

.customHyperlink {
  color: inherit !important;
  text-decoration: none;
}
</style>